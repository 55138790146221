.card-description {
  opacity: 1;
}
.card-item {
  background-color: #ffffff !important;
  box-shadow: unset !important;
  min-height: 460px !important;
}
.categoryitembutton {
  padding: 3px 10px;
  border-radius: 8px;
  font-size: 0.8rem;
  margin: 0 12px 0 0;
  white-space: nowrap;
}
.button-container {
  white-space: pre-line;
}
@media (min-width: 992px) {
  .card-container {
    overflow-x: hidden;
    padding: 0 1rem 1rem 1rem !important;
    display: grid;
    /* grid-gap: 3px; */
    grid-template-columns: auto auto auto auto auto;
  }
  .card-item {
    width: 18vw !important;
  }
}

.card-item {
  border-radius: 16;
  box-shadow: 0px 24px 64px rgba(0, 0, 0, 0.06) !important;
}
/* 
.card-item:hover .card-description {
  opacity: 1;
} */
