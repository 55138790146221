.counter {
  font-family: Muli;
  font-style: normal;
}

.counter section,
.counter section span {
  font-family: inherit !important;
  font-style: normal !important;
  font-size: inherit !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.counter .time {
  color: rgba(0, 0, 0, 0.54);
  letter-spacing: 0.14em;
  font-size: 30px;
  line-height: 62px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
}

.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
  opacity: 1;
  background: #17ce6a;
}

.owl-carousel .owl-stage {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1373px) {
  .testimonial_content .testimonial_cards .owl-item {
    flex-grow: 1;
    flex-basis: 0;
  }
}

.centralize-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  height: 100vh;
}
.imageResize {
  object-fit: contain;
}
.moile-imageResize {
  object-fit: cover;
}
