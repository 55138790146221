h3 {
  font-family: Muli;
  font-weight: bold;
  font-size: 24;
}
h6 {
  display: inline-block;
  font-family: "SF-Pro-Display-Bold", sans-serif;
  font-weight: bold;
  font-size: 18;
  color: #294948;
}
p.content-text {
  font-family: Muli;
  color: #294948;
  font-size: 18;
  font-weight: normal;
}
span.underline {
  /* text-decoration: underline; */
  border-bottom: 1px solid #294948 !important;
  font-weight: bold;
}
.plus-container {
  background: #18ce6a;
  border-radius: 100%;
  padding: 5px 5px;
}
.steplabelline {
  display: inline-block;
  /* display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  font-size: 18; */
}
.quarterimg-web {
  height: 150px;
  width: 400px;
  object-fit: cover;
  border-radius: 10px;
}
.quarterimg-mobile {
  height: 150px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.fullimg-web {
  height: 528px;
  width: 400px;
  object-fit: cover;
  border-radius: 10px;
}
.fullimg-mobile {
  height: 528px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.hrLine {
  width: 100%;
  height: 1px;
  background-color: rgba(41, 73, 72, 0.24);
}
.dummy-button {
  display: inline-block;
  font-size: 12px;
  color: #ffffff;
  background: #17ce6a;
  box-shadow: 0px 1px 2px rgba(117, 117, 117, 0.12);
  border-radius: 14px;
  margin: 0px 5px;
  padding: 1px 10px;
}
.help-text {
  font-size: 14px !important;
  color: #294948;
  font-weight: normal;
  
  /* font-weight: bold; */
}

.bold-link {
  font-weight: bold !important;
}
