.table-content {
  flex: 1;
}
.table-content3 {
  flex: 1.5;
}

.table-item-container {
  background-color: #F9F9F9;
  /* background-color: #C3C3C3; */
  border-radius: 10px;
}
